import MenuButton from "./components/Menu/MenuButton";
import Contact from "./Pages/Contact";
import HomePage from "./Pages/HomePage";
import Resume from "./Pages/Resume";
import About from "./Pages/About";
import Work from "./Pages/Work";
import Popup from "./components/popup/Popup";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ClientReviews from "./components/ClientReviews";
import Skills from "./Pages/Skills";
import Services from "./Pages/Services";

const App = () => {
  return (
    <>
      <MenuButton />
      <HomePage />
      <About />
      <Services />
      <Skills />
      <Resume />
      <Work />
      <ClientReviews />
      <Contact />
      <Popup />
    </>
  );
};

export default App;
