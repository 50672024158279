export const homePageData = {
  image: "https://avatars.githubusercontent.com/u/23386374?v=4",
  headings: [
    " Ahmad Raza",
    3000,
    " Full-stack Engineer",
    1000,
    " JavaScript Engineer",
    1000,
  ],
  description:
    "I am an enthusiastic Full Stack web and mobile app developer with expertise in Node.js, ExpressJS, NestJS, React, Redux, NextJS, and mobile application development.",
  socialLinks: [
    {
      id: 0,
      name: "whatsapp",
      link: "+923016446496",
      icon: "ic:baseline-whatsapp",
    },
    {
      id: 1,
      name: "upwork",
      link: "https://www.upwork.com/freelancers/ahmadraazaa",
      icon: "bxl:upwork",
    },
    {
      id: 2,
      name: "linkedin",
      link: "https://www.linkedin.com/in/ahmad-raza/",
      icon: "mdi:linkedin",
    },
    {
      id: 3,
      name: "github",
      link: "https://github.com/AhmadRaza786",
      icon: "mdi:github",
    },
    {
      id: 4,
      name: "instagram",
      link: "https://www.instagram.com/ahmad.raazaa/",
      icon: "mdi:instagram",
    },
    {
      id: 5,
      name: "facebook",
      link: "https://www.facebook.com/ahmad.raazaa",
      icon: "ic:baseline-facebook",
    },
    {
      id: 6,
      name: "twitter",
      link: "https://twitter.com/Ahmaduog055",
      icon: "mdi:twitter",
    },
  ],
};

export const contactPageData = {
  phone: ["+92 301-6446-496"],
  email: ["vision.ahmad@gmail.com"],
  whatsapp: ["+923016446496"],
  address: null,
  description:
    "Feel free to reach out to me. I'm here to help you bring your digital vision to life!",
};

export const resumePageData = {
  services: [
    {
      title: "Web App Development",
      icon: "iconoir:dev-mode-laptop",
      description:
        "Crafting innovative web solutions with cutting-edge technologies. My web app development services deliver seamless user experiences, performance optimization, and scalability for your digital success.",
    },
    {
      title: "Mobile Development",
      icon: "tabler:device-mobile-code",
      description:
        "Transforming ideas into functional reality with our expert mobile app development service. Seamlessly crafted, user-centric apps that engage, innovate, and elevate your business in the digital landscape.",
    },
    {
      title: "Deployment and DevOps",
      icon: "fluent-emoji-high-contrast:infinity",
      description:
        "My Deployment and DevOps service streamlines software delivery, automates infrastructure provisioning, and ensures seamless integration, enhancing agility, reliability, and collaboration throughout the development lifecycle.",
    },
  ],
  skills: [
    { title: "JavaScript", icon: "logos:javascript" },
    { title: "Typescript", icon: "devicon:typescript" },
    { title: "NodeJS", icon: "logos:nodejs-icon" },
    { title: "ReactJS", icon: "vscode-icons:file-type-reactjs" },
    { title: "Redux", icon: "devicon:redux" },
    { title: "NextJS", icon: "devicon:nextjs" },
    { title: "Postgres", icon: "logos:postgresql" },
    { title: "MongoDB", icon: "devicon:mongodb" },
    { title: "AWS", icon: "logos:aws" },
    { title: "GitHub", icon: "logos:github" },
    { title: "GitLab", icon: "logos:gitlab" },
    { title: "GraphQL", icon: "logos:graphql" },
    { title: "HTML-5", icon: "vscode-icons:file-type-html" },
    { title: "CSS-3", icon: "vscode-icons:file-type-css" },
  ],
  experiences: [
    {
      role: "Senior Full Stack Engineer (Freelancer)",
      date: "10/2019 - Present",
      company: "Upwork",
      descriptions: [
        `Freelance developer on Upwork offering a range of services in backend and frontend development, web development, and more.`,
        `Expertise in JavaScript/TypeScript, database management, external API integration, testing, optimization, and other development-related
        technologies.`,
        `Dedicated to delivering high-quality results, ensuring client satisfaction, and available for collaboration on your project.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "10/2021 - 05/2023",
      company: "Moment House",
      descriptions: [
        `Contributed to Moment's Checkout-SDK, implementing a seamless checkout process with event, merchant, and bundle stores, cart
      management, and checkout summary functionalities.`,
        `Developed modules for events management, profile management, user preferences, Patreon integration, payment methods integration (PayPal and Stripe), and user banning/muting to enhance functionality and user experience.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "08/2021 - 01/2022",
      company: "AIRecruiter",
      descriptions: [
        `Implemented advanced automation techniques using Node.js, AWS Lambda, Serverless framework, and DynamoDB to revolutionize the
        recruitment process for HR professionals at AIRecruiter.`,
        `Spearheaded the development of a cutting-edge platform that streamlined and optimized various stages of recruitment, empowering HR
        professionals to make data-driven decisions and improving the quality of hires.`,
        `Leveraged expertise in Node.js, AWS Lambda, Serverless framework, and DynamoDB to create a robust and efficient platform that exceeded
        client expectations, contributing to the success and positive impact of AIRecruiter in the HR industry.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "01/2020 - 04/2021",
      company: "Amazon",
      descriptions: [
        `Worked on Amazoncompass, a comprehensive project management tool at Amazon, streamlining tasks, team collaboration, QA strategies,
      surveys, and survey results.`,
        `Implemented messaging queues to automate email delivery for survey reports, enhancing efficiency and communication with stakeholders.`,
        `Leveraged skills in AWS SQS, React, AWS Lambda, Express.js, and Node.js to optimize project workflows and collaboration within the
      organization.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "05/2019 - 04/2021",
      company: "Warner Bros.",
      descriptions: [
        `Developed WBPOP, an e-commerce platform connecting merchants with newly released series, enhancing the shopping experience.`,
        `Played a crucial role in building SIMS, a Social Influencer Management System, maximizing product visibility through effective social
      campaigns.`,
        `Created the Status App, optimizing ticket management by monitoring open, closed, and in-progress tickets across multiple applications.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "01/2019 - 04/2021",
      company: "Makeen Technologies",
      descriptions: [
        `Developed Vyoo App, a social media platform for professionals, enabling event notifications, chats, and friend connections.`,
        `Revolutionized the nursing industry with HolliBlu, offering innovative solutions for nurses and healthcare companies.`,
        `Created Finch, a unified integration system for secure access to organizational data across multiple HRIS and payroll systems.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "12/2017 - 12/2018",
      company: "Onstak Inc",
      descriptions: [
        `Developed a real-time monitoring platform for multiple AI sensors, ensuring continuous tracking and transmission of sensor updates to an
      application dashboard.`,
        `Implemented socket communication protocol for seamless and efficient data exchange between sensors and the application, enabling
      immediate detection and relay of changes in sensor states.`,
        `Designed an application dashboard to present sensor data in a visually appealing and user-friendly manner, allowing users to monitor AI
      sensor performance and behavior in real-time.`,
      ],
    },
    {
      role: "Senior Full Stack Engineer",
      date: "11/2016 - 12/2017",
      company: "ITBOOST",
      descriptions: [
        `Contributed to the development of TBOOST, a dynamic documentation platform for MSPs, revolutionizing the management and organization
      of employee documentation in the US market.`,
        `Designed and implemented core modules, playing a vital role in building the platform from scratch and ensuring a successful product
      launch.`,
        `Utilized cutting-edge technologies like React, Redux, Node.js, and MongoDB to develop and integrate various features, delivering seamless
      and intuitive user experiences and contributing to the platform's growth and impact in the industry.`,
      ],
    },
  ],
};

export const aboutPageData = {
  image: "https://tf-react-bieber.vercel.app/images/about-image.jpg",
  heading: "Ahmad Raza",
  experienceList: [
    "✨ 10 years of experience",
    "💎 Worked with Fortune 100 companies",
    "✅ 50+ projects delivered",
    "✉️ 2.3 million qualified leads generated for clients",
    "📈 6.9 million transactions generated for clients",
  ],
  description: [
    "Greetings! I'm Ahmad Raza, A Full-Stack Web Developer, Designer & Media Buyer with 10 years of experience in development, With more than 520 ⭐⭐⭐⭐⭐ reviews online and partner distinctions from Google, Facebook, Salesforce, and more, I am uniquely qualified to help your business get more leads, transactions, and revenue.",
    "With 10 years of full-stack development experience, I'm proficient in APIs like Google Maps, Foursquare, Stripe, and PayPal. My expertise includes React, Next.js, Node.js, MongoDB, PostgreSQL, and AWS, and I'm eager to take on complex challenges and deliver innovative solutions.",
    "In the last 10 years, I have worked with many Fortune 100 companies and big brands such as Amazon, Warner Brothers, and Moment House. I have completed more than 50 projects for companies from over 16 countries. I have a 100% track record of delivering projects on time. Not an inch past the deadline, that's my personal credo.",
  ],
  reviews: [
    {
      image: "https://www.makeen.io/wp-content/uploads/2020/11/55.png",
      name: "Dan Ochiana",
      designation: "CTO @ Makeen Technologies",
      feedback:
        "I hired Ahmad to help with various JavaScript backend (NodeJS/NestJS) and frontend efforts (React, Redux). He was reliable, flexible, and very responsive. Highly recommend!",
    },
    {
      image:
        "https://media.licdn.com/dms/image/C5603AQG0i3rz2vlamQ/profile-displayphoto-shrink_800_800/0/1607397313319?e=1697068800&v=beta&t=PFy3bzleKbGrcRR7dwuTBAEOrUvcqQ7rleXfCDab2nU",
      name: "Pietro Sette",
      designation: "Director Engineering @ MomentHouse",
      feedback: `If you're looking for someone dependable to get the job done, Ahmad is your guy. He's got a good handle on the whole project process, from the early talks with stakeholders right through to delivering the final product. He's worked well across the entire React/Typescript/Node stack working with external dependencies and apis, so he can tackle different parts of the software stack pretty comfortably. Ahmad knows his stuff technically, and delivers steady, quality work that you can count on. His experience and skills would be a real plus for any team.`,
    },
    {
      image:
        "https://media.licdn.com/dms/image/C5603AQGo7jqzaem4wg/profile-displayphoto-shrink_800_800/0/1619486744716?e=1697068800&v=beta&t=tyP-mw6TePp6zPLuhlmW4bCzDo8kUL_bK4bRU0rW3_o",
      name: "Stephen Dickens",
      designation: "Team Lead @ MomentHouse",
      feedback: `Ahmad is a skilled engineer with experience building a few fairly complex features in apps using a Node/NextJS/React stack. His contributions helped push our platform to new levels with improved functionally and some UX enhancements. 
  
      If you need someone who is reliable and can turn product ideas into something functional, Ahmad would be a great asset to have on your team.`,
    },
    {
      image:
        "https://ca.slack-edge.com/T025RNWEZJ4-U025849VBJ8-f2f43eb130e8-512",
      name: "Ricardo",
      designation: "CTO @ HOAH",
      feedback:
        "Ahmad has great knowledge on a wide range of technologies and, as the main developer for our project, was responsible for the creation of almost all of both backend and front-end code.",
    },
    {
      image:
        "https://media.licdn.com/dms/image/C5603AQFlS4PRzNqu4Q/profile-displayphoto-shrink_800_800/0/1612327797603?e=1697068800&v=beta&t=Hl7UtbFPx4fzmJkuqV5xFM13j4yBjPvfmk1ddxyYrNo",
      name: "Nour Betar",
      designation: "Team Lead @ AiRecruiter",
      feedback:
        "Ahmad is very skilled, and can definitely get the job done no matter the task.",
    },
    {
      image:
        "https://media.licdn.com/dms/image/D4D03AQEKvAYy4GdA0w/profile-displayphoto-shrink_400_400/0/1669927720626?e=1697068800&v=beta&t=HKpGzcFXO-zrnXRe1e9uxoUkFhFQ3IY3HQsIB99966E",
      name: "Kevin",
      designation: "Upwork Client",
      feedback: `Ahmed always delivers impeccable results. Clear communication and problem-solving are his defining traits. Working with him has been a joy due to his strong sense of responsibility. Among the developers I've met on Upwork, he is the best. His JavaScript/React skills are at an expert level. Thank you once again`,
    },
  ],
};
export const workPageData = [
  {
    id: "1",
    title: "Moment House",
    category: ["React", "Node"],
    link: "https://www.moment.co/",
    thumbnail: {
      title: "Landing Page ",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/5f084219-f4f6-43eb-9b0a-998ef2f20567",
    },
    projectData: [
      { title: "Role", description: "Sr. Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "In this case study, we will explore the process of designing and developing a web application for MomentHouse, a popular SaaS for hosting live events. The goal of the project was to build some complex features that effectively showcased the benefits of the SaaS and facilitated online events.",
      },
      {
        title: "Client Background:",
        description:
          "MomentHouse is a US-based company that hosts virtual live events of celebrities for their fans through a web application. Their mission is to build a live streaming platform for celebrities where the fan can enter, enjoy live concerts, and connect with other fans via chat/group chat.",
      },
      {
        title: "Solution:",
        description:
          "I implemented the solutions to the above problems for Momenthouse as",
        bullets: [
          "Checkout-SDK: A complete independent checkout flow that can be reused in multiple applications.",
          "Patreon Integration: Enable fans to be the patron and get exclusive discounts.",
          "Banning/Muting Users: Enable the platform to ban/Mute user so that the application always have clean and valid content.",
          " Images/Chat Moderation: Enable Moderation for chat and images so that users can not use bad words in chat as well as do not upload unprofessional images.",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the Saas. It helped Momenthouse to increase their sales by 30% and of course, reducing the tech debt too was also increasing the profits.",
      },
      {
        title: "Conclusion",
        description: `MomentHouse got redesigned its complete checkout flow that was more eye-catching and easy for users to buy tickets and merch items. Also, patrons were allowed to get exclusive discounts, chat, and images got clean and reporting feature was just awesome.
All these reduced the tech debt of MomentHouse and attracted more clients and investors to the platform.  All these reduced the tech debt of MomentHouse and attracted more clients and investors to the platform.
`,
      },
    ],
    projectImages: [
      {
        title: "Creator Page",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/7f7d1ae4-0cae-4165-a544-2cd3a97b8676",
      },

      {
        title: "Login ",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/ba774949-16f8-4e20-884d-33489fd908b1",
      },
    ],
  },
  {
    id: "2",
    title: "Moment Worlds",
    category: ["React", "Node"],
    link: "https://paris.world.co",

    thumbnail: {
      title: "Paris World ",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/f4ba0c10-9fcc-4a00-8902-dcd6beec5d17",
    },
    projectData: [
      { title: "Role", description: "Sr. Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "In this case study, we will explore the process of designing and developing a web application for MomentHouse, a popular SaaS for hosting live events. The goal of the project was to build some complex features that effectively showcased the benefits of the SaaS and facilitated online events.",
      },
      {
        title: "Client Background:",
        description:
          "MomentHouse is a US-based company that hosts virtual live events of celebrities for their fans through a web application. Their mission is to build the virtual worlds of celebrities where the fan can enter, play with their avatars, enjoy live concerts, and connect with other fans via char/group chat.",
      },
      {
        title: "Challenge:",
        description:
          "The client needed some complex features to be built on their application like          ",
        bullets: [
          "They were planning to build multiple applications with the same backend with ust differences in frontend designs. The whole checkout flow needed to be implemented for the second application.",
          "Offer exclusive discounts for patrons.          ",
          "There was no restriction for users while uploading images and chatting. Users were able to upload bad images and could use abusive language in the chat.",
          " The platform needed the reporting feature to ban or mute the users from the platform. ",
        ],
      },
      {
        title: "Solution:",
        description:
          "I implemented the solutions to the above problems for Momenthouse as          ",
        bullets: [
          "Checkout-SDK: A complete independent checkout flow that can be reused in multiple applications.",
          " Patreon Integration: Enable fans to be the patron and get exclusive discounts.          ",
          "Banning/Muting Users: Enable the platform to ban/Mute user so that the application always have clean and valid content.",
          "  Images/Chat Moderation: Enable Moderation for chat and images so that users can not use bad words in chat as well as do not upload unprofessional images.",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the Saas. It helped Momenthouse to increase their sales by 30% and of course, reducing the tech debt too was also increasing the profits.",
      },
      {
        title: "Conclusion",
        description: `MomentHouse got redesigned its complete checkout flow that was more eye-catching and easy for users to buy tickets and merch items. Also, patrons were allowed to get exclusive discounts, chat, and images got clean and reporting feature was just awesome.
        All these reduced the tech debt of MomentHouse and attracted more clients and investors to the platform.
`,
      },
    ],
    projectImages: [
      {
        title: "Merch Store",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/f9944d99-8f8b-4826-874a-eb9b1d12fae5",
      },

      {
        title: "Checkout Flow",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/ad42370f-aad0-43d4-a0a1-2ce4313b91b7",
      },
      {
        title: "Shopping Bag",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/f29a4421-b501-435d-86ed-848708d8b2e1",
      },
      {
        title: "Login",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/71f04fbf-62c9-4698-9513-7e1ac613f624",
      },
    ],
  },
  {
    id: "3",
    title: "Bayut",
    category: ["React", "Node"],
    link: "https://bayut.com",

    thumbnail: {
      title: "Landing Page",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/1dfdf12d-81cd-4930-ab51-d3b242cc2615",
    },
    projectData: [
      { title: "Role", description: "Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "In this case study, we will explore the process of designing and developing a web application for Bayut, With a user-friendly interface, you can easily find your dream home, luxurious penthouse, or prime investment opportunity. The goal of the project was to build some complex features that effectively showcased the benefits of the platform.          ",
      },
      {
        title: "Client Background:",
        description:
          "Bayut, a prominent UAE-based real estate company, is driven by an ambitious vision to revolutionize the property landscape using its innovative web application. Their primary mission is to empower individuals and businesses by offering the market's most comprehensive, reliable, and user-friendly real estate platform.",
      },
      {
        title: "Challenge:",
        description:
          "The client needed some complex features to be built on their application like          ",
        bullets: [
          "Real estate agencies registration and management",
          "Fast Property search feature",
          "More accurate search using maximum filters to get a best matching property",
          "Featured properties management ",
        ],
      },
      {
        title: "Solution:",
        description:
          "I implemented the solutions to the above problems for Bayut as          ",
        bullets: [
          "Implemented a complete agency management system, where agents can register their agencies and link their properties to their agency So that all properties can be found by the agency name.",
          "Implemented Algolia search (IndexDB) to make the search faster and regular migration of data from MongoDB to Algolia search to keep the search up to date.",
          "Added advanced filters like area range, price range, type(rent/sale), number of rooms, furnished or not, and nearby location. All these filters make the search most accurate and find the most relevant and suitable property for the users.",
          " Some agencies/clients wanted to make their properties to be featured so that users can see them on the first page. This feature increased the traffic on the site as well as increased the sales of the properties.          ",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the platform. It helped Bayut to increase its sales by 42% and of course, increased the traffic by 80%.",
      },
    ],
    projectImages: [
      {
        title: "Featured Agencies        ",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/af99bae4-55c9-4945-b186-62fbc972345a",
      },

      {
        title: "Property Search        ",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/46ba6f88-a850-4ea2-b89f-9b8290404890",
      },
    ],
  },
  {
    id: "4",
    title: "Vyoo App",
    category: ["React", "Node"],
    link: "https://vyoo.me",

    thumbnail: {
      title: "Web",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/3a1c032e-ad0a-4189-ae21-0853f60432b9",
    },
    projectData: [
      { title: "Role", description: "Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "In this case study, we will explore the process of designing and developing a mobile application’s backend for Vyoo, a popular social media application for professional communities. The goal of the project was to build some complex features that effectively showcased the benefits of the mobile app and facilitated professional communities.",
      },
      {
        title: "Client Background:",
        description:
          "Vyoo is a US-based company that owns a mobile application named Vyoo App. Vyoo actually aims to allow professionals to connect with each other through professional events.",
      },
      {
        title: "Challenge:",
        description:
          "The client needed some complex features to be built on their application like ",
        bullets: [
          "Searching nearby events by setting a specific value of the radius by the user.",
          "Making connections and allowing them to chat in the form of groups as well as individual chat.",
          "Posts, comments, and likes/reactions features needed to be built.",
          "Filtering out the bad Images/Video to allow only clean content.",
        ],
      },
      {
        title: "Solution:",
        description:
          "I implemented the solutions to the above problems for Momenthouse as ",
        bullets: [
          "Built a feature to search the nearby events and vyooers using elasticsearch queries, also made it faster to get quick results on the mobile application.",
          " A complete chat feature was introduced for group chat as well as individual chats.",
          "Built the boards to post the content and enable users to add reactions to the post, commenting on the post, and sharing the post.",
          " Introduced Images and videos moderation to allow users to only post clean content.",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the mobile app. It increased the user joining ratio to 100% and increased its sales by 40%.",
      },
    ],
    projectImages: [
      {
        title: "Home",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/e6b46f25-c066-41aa-ba35-7de785604db8",
      },

      {
        title: "Groups",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/6911aa27-62aa-489a-b308-bb1a14eb962a",
      },
    ],
  },
  {
    id: "5",
    title: "ROQ BAAS (Backend as a service)",
    category: ["React", "Node"],
    link: "https://roq.tech",

    thumbnail: {
      title: "Landing Page      ",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/bc7c7e7a-0e2a-4bf8-8c2a-1299a3d89219",
    },
    projectData: [
      { title: "Role", description: "Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "This project developed an independent Backend as a Service (BAAS) with reusable features, injectable into any web app. It offered user auth, database management, and API integration via a RESTful API. Scalable, secure, and well-documented, it saved time and empowered developers to focus on frontends.          ",
      },
      {
        title: "Client Background:",
        description:
          "ROQ, a German company, offers No code solutions for swift web app development, enabling clients to build applications quickly. Their built-in microservices for significant features expedite the process, reducing development time.          ",
      },
      {
        title: "Challenge:",
        description:
          "The client needed some independent micro-services for their BAAS like          ",
        bullets: [
          "An independent micro-service to enable notifications, emails, sms alerts according to the given parameters.",
          "An Independent micro-service to be an ACL(Access Control Layer) that manages the authorization and manage permissions according to the given roles.",
        ],
      },
      {
        title: "Solution",
        description:
          "I implemented the solutions to the above problems for ROQ as",
        bullets: [
          "Built a micro-service with super generic code that is injectable to a web application that accepts the parameters and shoots the emails, sms alerts, and notifications accordingly.",
          "Built a micro-service-based solution to perform user authorization according to the given roles and their permission. It also applied to database queries if a user role is not allowed to post anything to the database, then if he tries to post anything, the DB query itself throws an error. The multi-layer authorization makes the ACL mature and worthy.",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the BAAS. It helped ROQ to increase their sales by 30% and of course, reducing the tech debt too was also increasing the profits.",
      },
    ],
    projectImages: [
      {
        title: "ROQ docs",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/6ed58505-ba81-4d9d-b675-48efffa0cf4c",
      },

      {
        title: "Pricing",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/841e0a32-4770-4651-9a7f-b100741f8c20",
      },

      {
        title: "Features",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/04cf7987-1fd4-47c4-9afb-26d5491b4805",
      },
    ],
  },
  {
    id: "6",
    title: "HOAH (House Owners Association)    ",
    category: ["React", "Node"],
    link: "https://www.hoah.rentals",

    thumbnail: {
      title: "Landing Page      ",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/e536432f-9f08-4be9-a546-edc75eadc609",
    },
    projectData: [
      { title: "Role", description: "Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "In this case study, we will explore the process of designing and developing a web application for HOAH, the House Owners Association services platform. The goal of the project was to build some complex features that effectively showcased the benefits of the Platform and facilitated the real estate industry.          ",
      },
      {
        title: "Client Background:",
        description:
          "HOAH is a German-based Startup that provides automated services for managing the properties of house owners. Keeping a record of all properties, managing taxes, and all legal matters via an online platform.",
      },
      {
        title: "Challenge:",
        description:
          "The client needed to build this platform from scratch within 1 month to facilitate his active real estate clients. The challenges were          ",
        bullets: [
          "Building an eye caching and user-friendly platform for the users.",
          "Demo video to educate the users on how to use the platform for their legal matters.          ",
          "Managing all the sold properties, and rented properties.         ",
          "Verification of the user-registered properties and connecting the house managers too with the platform.     ",
          "keeping the platform up-to-date with the latest records of the properties. If govt announced any illegality or any action to any specific property.    ",
        ],
      },
      {
        title: "Solution",
        description:
          "I implemented the solutions to the above problems for HOAH (Startup) as",
        bullets: [
          "Built a complete responsive website for the client",
          "Demo video for users to understand the usage of the platform.",
          "Allowing users to add the properties and also add a legal document as proof of the property.",
          "Setting the status of the property if it's sold or rented.",
          " Engaging a third-party API to get the latest status of the property to keep the platform up-to-date.          ",
          "Building an eye-catching dashboard with Light signals to indicate if the property is verified, under verification, or illegal.          ",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the Platform. It helped the client to increase the sale to 60% and within a couple of months, this platform holds and managed 200+ properties.",
      },
    ],
    projectImages: [
      {
        title: "Dashboard (Properties)        ",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/0269b0c2-6b1c-4778-98d5-96a8a6885ad4",
      },

      {
        title: "Dashboard (Service Systems)        ",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/e4a69554-0197-4712-a265-e510a3ec65d9",
      },
    ],
  },
  {
    id: "7",
    title: "AiRecruiter   ",
    category: ["React", "Node"],
    link: "https://airecruiter.com",

    thumbnail: {
      title: "Landing Page      ",
      src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/cf5132b1-ee20-4f36-88db-e8c238e97f9a",
    },
    projectData: [
      { title: "Role", description: "Full Stack Engineer" },
      {
        title: "Introduction",
        description:
          "In this case study, we will explore the process of designing and developing a super-smart recruitment management system using AI. It contained automated campaigns that reduces recruiter efforts by 40%.  ",
      },
      {
        title: "Client Background:",
        description:
          "AiRecruiter, A leading recruiting platform company that provides a platform for recruitment agencies to facilitate recruiters, smart usage of AI, and super easy and user-friendly UI for building campaigns and workflows with drag-and-drop features.",
      },
      {
        title: "Challenge:",
        description:
          "The client needed some complex features to be built on their application like ",
        bullets: [
          "Building a complex dashboard for the Recruiter to showcase the total summary on one page.",
          "Building workflows in such a way that is the most user-friendly.     ",
          "Real-time workflow tracking on the admin panel.   ",
          "Automated campaigns management",
        ],
      },
      {
        title: "Solution",
        description:
          "I implemented the solutions to the above problems for AiRecruiter as",
        bullets: [
          "Built a complex dashboard for the Recruiter to showcase the total summary on one page like no of job posts, no of applicants, filtering out the applicants using the advanced filters, In Process of recruitment statuses, Rejected applicants, etc.",
          "Built the workflows using drag-and-drop functionality. So users can just drag the workflow items to build a complete and planned workflow.",
          "Admin gets notified when any recruiter tried to change the existing workflow and rejects the changes if they are not fruitful.",
          "Enabled platform to run automated campaigns of sending emails with one click.          ",
        ],
      },
      {
        title: "Results",
        description:
          "All of the above complex features were implemented completely which attracted more clients and investors to the platform. It helped Bayut to increase its sales by 22% and of course, increased the traffic by 50%.          ",
      },
    ],
    projectImages: [
      {
        title: "Integrations",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/e2b46c01-cc0f-4504-b2d0-baf9fc248e43",
      },

      {
        title: "Features",
        src: "https://www.upwork.com/att/download/portfolio/persons/uid/1011215948188753920/profile/projects/files/6c761abb-7143-4653-9aa3-f95733b35d1f",
      },
    ],
  },
];
