import { FC, ReactNode, useState } from "react";
import styled from "styled-components";

interface IconBoxProps {
  children: ReactNode;
  href: string;
  dataTooltipId: string;
  dataTooltipContent: string;
}

const IconBox: FC<IconBoxProps> = ({ children, href , dataTooltipId , dataTooltipContent} , props) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered((pre) => !pre);
  };

  console.log(hovered.toString());
  return (
    <IconBoxWarpper
      href={href}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      target="_blank"
      data-tooltip-content={dataTooltipContent}
      data-tooltip-id ={dataTooltipId }
    >
      <StyledIconBox hovered={hovered.toString()}>{children}</StyledIconBox>
      <HoverdIconBox hovered={hovered.toString()}>{children}</HoverdIconBox>
    </IconBoxWarpper>
  );
};

const IconBoxWarpper = styled.a`
  line-height: 1;
  font-size: 18px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgb(255 255 255/0.1);
  border-radius: 0.25rem;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

const StyledIconBox = styled.div<{ hovered: string }>`
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  transform: ${(props) =>
    props.hovered === "true"
      ? "translate(0, -100%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)"
      : "translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)"};
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: rgb(15 23 42/1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;

  > svg {
    color: #bfbecb;
  }
`;

const HoverdIconBox = styled(StyledIconBox)`
  transform: ${(props) =>
    props.hovered === "true"
      ? "translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)"
      : "translate(0, 100%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)"};
  position: absolute;
  background-color: rgb(0 180 216);
  > svg {
    color: #0f172a;
  }
`;

export default IconBox;
