import { FC } from "react";
import styled from "styled-components";
import Reveal from "./Reveal";
import { Icon } from "@iconify/react";

interface InfoCardProps {
  icon: string;
  title: string;
  description: string[];
}

const InfoCard: FC<InfoCardProps> = ({ icon, title, description }) => {
  const checkDescription = (desc: string) => {
    const emailRgx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const phoneRgx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    if (emailRgx.test(desc)) {
      return "email";
    }
    if (phoneRgx.test(desc)) {
      return "phone";
    }
    return "text";
  };

  return (
    <Reveal width="100%">
      <Container>
        <Content>
          <div>
            <IconBox>
              {" "}
              <Icon icon={icon} />{" "}
            </IconBox>
          </div>
          <InfoBox>
            <Title>{title}</Title>
            {description.map((i) => (
              <Des key={i}>
                {checkDescription(i) === "email" ? (
                  <a target="_blank" href={`mailto:${i}`}>
                    {i}
                  </a>
                ) : title.indexOf("WhatsApp") !== -1 ? (
                  <a target="_blank" href={`https://wa.me/${i}`}>
                    {i}
                  </a>
                ) : checkDescription(i) === "phone" ? (
                  <a target="_blank" href={`tel:${i}`}>
                    {i}
                  </a>
                ) : (
                  <span>{i}</span>
                )}
              </Des>
            ))}
          </InfoBox>
        </Content>
      </Container>
    </Reveal>
  );
};

const Container = styled.div`
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255 255 255/0.1);
  background-color: rgb(22 32 51/1);
  backdrop-filter: blur(12px);
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
`;
const IconBox = styled.div`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b4d822;
  border-radius: 999px;
  svg {
    color: #00b4d8;
  }
`;

const InfoBox = styled.div``;

const Title = styled.h5`
  color: #fff;
  word-break: break-word;
  margin-top: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-top: 0px;
`;

const Des = styled.p`
  margin-bottom: 0;
  a,
  span {
    color: rgb(191 190 203/1);
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    text-decoration: none;
  }
`;
export default InfoCard;
