import { Icon } from "@iconify/react";
import styled from "styled-components";
import Reveal from "../components/Reveal";
import { resumePageData } from "../portfolio";
import PageHeading from "../components/PageHeading";
import { Element } from "react-scroll";
import Section from "../components/Section";

const Services = () => {
  return (
    <Section name="services">
      <Content>
        <Row>
          {resumePageData.services.map(
            (i: { title: string; icon: string; description: string }) => (
              <Col key={i.title}>
                <Reveal overflow="visible">
                  <ServiceContainer>
                    <Warpper>
                      <IconBox>
                        <Icon icon={i.icon} />
                      </IconBox>
                      <Title>{i.title}</Title>
                    </Warpper>
                    <Description>{i.description}</Description>
                  </ServiceContainer>
                </Reveal>
              </Col>
            )
          )}
        </Row>
      </Content>
    </Section>
  );
};

const Content = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  gap: 1.75rem;
  flex-wrap: wrap;

  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
`;

const Col = styled.div`
  flex: 1 1 305px;
  height: 100%;

  @media (min-width: 1024px) {
    flex: 1 1 205px;
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ffffff24;
  border-radius: 10px;
  padding: 15px;
  gap: 10px;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconBox = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191f30;
  > svg {
    height: 50px;
    width: 50px;
    color: #fff;
  }
`;

const Title = styled.h3`
  color: rgb(0, 180, 216);
`;

const Description = styled.p`
  color: #fff;
`;

const Warpper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default Services;
