import styled from "styled-components";
import PageHeading from "./PageHeading";
import { Element } from "react-scroll";

const Section = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name: string;
}) => {
  return (
    <Element name={name}>
      <Container>
        <PageHeading heading={name} backgroundText={name} />

        <Warpper>{children}</Warpper>
      </Container>
    </Element>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: rgb(11 18 35/0.9);
  z-index: 20;
  padding-top: 6rem;
  padding-bottom: 6rem;
`;
const Warpper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export default Section;
