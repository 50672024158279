import { FC } from "react";
import ResumeCard from "./ResumeCard";
import Reveal from "./Reveal";
import { resumePageData } from "../portfolio";

interface ExperiencesProps {}

const Experiences: FC<ExperiencesProps> = ({}) => {
  return (
    <>
      {resumePageData.experiences.map((i, n) => (
        <Reveal key={n.toString()} width="100%" overflow="visible">
          <ResumeCard
            date={i.date}
            role={i.role}
            company={i.company}
            descriptions={i.descriptions}
          />
        </Reveal>
      ))}
    </>
  );
};

export default Experiences;
