import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import MenuToggle from "./MenuToggle";
import { Navigation } from "./Navigation";
import "./style.css";
import styled from "styled-components";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const MenuButton = () => {
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [isOpen, setisOpen] = React.useState(false);
  const [show, setIsShow] = React.useState(false);

  const menuHandler = () => {
    setisOpen(!isOpen);
  };

  React.useEffect(() => {
    if (!!show) {
      setTimeout(() => {
        setIsShow(isOpen);
      }, 700);
    } else {
      setIsShow(isOpen);
    }
  }, [isOpen]);

  return (
    <Container
      onClick={menuHandler}
      style={{
        zIndex: show ? 99 : 0,
        height: show ? "100vh" : "0px",
        position: show ? "fixed" : "static",
      }}
    >
      <Nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <MenuToggle toggle={menuHandler} />

        <Background variants={sidebar} />
        <Navigation
          onClick={menuHandler}
          style={{ display: show ? "block" : "none" }}
        />
      </Nav>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Nav = styled(motion.nav)`
  width: 300px;
`;

const Background = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #191f30;
`;

export default MenuButton;
