import { FC } from "react";
import styled from "styled-components";
import ContactForm from "../components/ContactForm";
import InfoCard from "../components/InfoCard";
import PageHeading from "../components/PageHeading";
import { contactPageData } from "../portfolio";
import { Element } from "react-scroll";
import Section from "../components/Section";

interface ContactProps {}

const Contact: FC<ContactProps> = ({}) => {
  return (
    <Section name="contact">
      <Content>
        <Warpper>
          <div>
            <Heading>Contact Information</Heading>
            <Desc>{contactPageData.description}</Desc>
            <Devider />
          </div>
          <InfoCard
            title="Contact on phone"
            description={contactPageData.phone}
            icon={"ic:baseline-phone"}
          />
          <InfoCard
            title="Contact on mail"
            description={contactPageData.email}
            icon={"fa-solid:envelope"}
          />
          {contactPageData?.whatsapp && (
            <InfoCard
              title="Contact on WhatsApp"
              description={contactPageData?.whatsapp}
              icon={"ic:baseline-whatsapp"}
            />
          )}
          {contactPageData?.address && (
            <InfoCard
              title="Contact address"
              description={contactPageData?.address}
              icon={"bxs:map"}
            />
          )}
        </Warpper>
        <Warpper>
          <ContactForm />
        </Warpper>
      </Content>
    </Section>
  );
};

const MainContainer = styled.div`
  width: 100%;
  background-color: rgb(11 18 35/0.9);
  z-index: 20;
  padding-top: 6rem;
  padding-bottom: 6rem;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  max-width: 768px;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 100%;
    flex-direction: row;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
`;

const Warpper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 1024px) {
    max-width: 625px;
  }
`;

const Heading = styled.h4`
  font-size: 22px;
  line-height: 1.4;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: rgb(191 190 203/1);
`;

const Devider = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  width: 5rem;
  height: 0.25rem;
  border-radius: 999px;
  background-color: #00b4d822;
`;

export default Contact;
