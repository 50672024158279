import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

type FormValues = {
  name: string;
  subject: string;
  email: string;
  message: string;
};

const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const templateParams = {
        // Your Email.js template parameters
        to_name: "Ahmad Raza",
        from_name: data.name,
        subject: `Message from iamahmadraza.com`,
        from_email: data.email,
        message: data.message,
      };

      const response = await emailjs.send(
        "service_lu2rhr9", // Your Email.js service ID
        "template_6xq9jil", // Your Email.js template ID
        templateParams,
        "l-GEmmF2zRVptt5wu" // Your Email.js user ID
      );

      if (response.status === 200) {
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          placeholder="Name"
          {...register("name", { required: "Name is required" })}
        />
        {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
      </Fieldset>

      <Fieldset>
        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          placeholder="Email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      </Fieldset>

      <Fieldset>
        <Label htmlFor="message">Message</Label>
        <Textarea
          placeholder="Message"
          {...register("message", { required: "Message is required" })}
        />
        {errors.message && (
          <ErrorMessage>{errors.message.message}</ErrorMessage>
        )}
      </Fieldset>

      <Button type="submit">Submit</Button>
    </Form>
  );
};

const Form = styled.form`
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255 255 255/0.1);
  background-color: rgb(22 32 51/1);
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Fieldset = styled.fieldset`
  border: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  color: #fff;
`;

const Input = styled.input`
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgb(15 23 42/0.1);
  color: rgb(191 190 203/1);
  border: 1px solid rgb(255 255 255/0.1);
  border-radius: 0.25rem;
`;
const Textarea = styled.textarea`
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgb(15 23 42/0.1);
  color: rgb(191 190 203/1);
  border: 1px solid rgb(255 255 255/0.1);
  border-radius: 0.25rem;
  min-height: 150px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  vertical-align: middle;
`;

const Button = styled.button`
  display: inline-block;
  height: auto;
  min-height: 0;
  width: auto;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.25rem;
  border-width: 0;
  background-color: #00b4d8;
  padding: 0.6rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: rgb(15 23 42/1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
`;

const ErrorMessage = styled.p`
  color: rgb(239 68 68/1);
`;

export default ContactForm;
