import { FC } from "react";
import styled from "styled-components";
import PageHeading from "./PageHeading";
import { Carousel } from "react-responsive-carousel";
import { aboutPageData } from "../portfolio";
import { Element } from "react-scroll";
import Section from "./Section";

interface ClientReviewsProps {}

const ClientReviews: FC<ClientReviewsProps> = ({}) => {
  return (
    <Section name="feedback">
      <SliderContainer>
        <Carousel
          showThumbs={false}
          showArrows={true}
          showStatus={false}
          emulateTouch={true}
          infiniteLoop={true}
          centerMode={true}
          centerSlidePercentage={100}
          swipeScrollTolerance={10}
          interval={5000}
          autoPlay
        >
          {aboutPageData.reviews.map((item) => (
            <SliderDiv key={item.name}>
              <SliderImgDiv>
                <SliderImg src={item.image} alt="ahmad" />
              </SliderImgDiv>
              <SliderHeading>{item.name}</SliderHeading>
              <SliderSubHeading>{item.designation}</SliderSubHeading>
              <SliderDesc>{item.feedback}</SliderDesc>
            </SliderDiv>
          ))}
        </Carousel>
      </SliderContainer>
    </Section>
  );
};

const SliderContainer = styled.div`
  margin: 0px auto;
  width: 100%;
  padding-top: 100px;
  .carousel-root {
    width: 80%;
    margin: auto;
  }
`;

const SliderDiv = styled.div`
  width: auto;
  height: auto;
  position: relative;
  transition-property: transform;
  flex-shrink: 0;
  box-sizing: border-box;
  background: #162033;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  border-radius: 15px;
  margin: 70px auto;
  @media screen and (min-width: 768px) {
    width: 450px;
  }
  @media screen and (min-width: 1024px) {
    width: 600px;
  }
  @media screen and (min-width: 1440px) {
    width: 800px;
  }
`;

const SliderImgDiv = styled.div`
  width: 80px;
  height: 80px;
  border: 4px solid #00b4d8;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  margin-top: -3.8rem;
  display: inline-block;
  float: left;
  @media screen and (min-width: 1024px) {
    width: 120px;
    height: 120px;
    margin-top: -5.5rem;
  }
`;

const SliderImg = styled.img`
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 100%;
`;

const SliderHeading = styled.h5`
  font-size: 15px;
  color: #fff;
  margin-top: 30px;
  @media screen and (min-width: 1024px) {
    font-size: 18px;
  }
`;

const SliderSubHeading = styled.p`
  color: #bfbecb;
  font-size: 12px;
  margin-top: 10px;
  @media screen and (min-width: 1024px) {
    font-size: 14px;
  }
`;

const SliderDesc = styled.p`
  font-size: 10px;
  color: #bfbecb;
  margin-top: 15px;
  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
`;
export default ClientReviews;
