import { FC } from "react";
import { Icon } from "@iconify/react";
import IconBox from "./IconBox";

interface SocialIconsProps {
  icon: { name: string; link: string; icon: string ;id: number };
}

const SocialIcons: FC<SocialIconsProps> = ({ icon }) => {
  return (
    <>
      <IconBox
        key={icon.name}
        href={
          icon.name === "whatsapp" ? `https://wa.me/${icon.link}` : icon.link
        }
        dataTooltipId={`social-icon-${icon.id}`} dataTooltipContent={icon.name}
      >
        <Icon icon={icon.icon} />
      </IconBox>
    </>
  );
};

export default SocialIcons;
