import { FC, useEffect, useRef } from "react";
import { motion, useAnimation , useInView } from "framer-motion";

interface RevealProps {
  children: React.JSX.Element;
  width?: "fit-content" | "100%";
  overflow?: "hidden" | "visible"
}

const Reveal: FC<RevealProps> = ({ children, width = "fit-content" ,overflow = "hidden"  }) => {
    const ref = useRef(null)
    const isInView = useInView(ref , {once: true})
    const mainControls = useAnimation()

    useEffect(() => {
        if(isInView){
            mainControls.start("visible")
        }

    } , [isInView])

  return (
    <div ref={ref} style={{ position: "relative", width, overflow }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{duration : 0.5, delay : 0.3}}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Reveal;
