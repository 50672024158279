import React from "react";
import { usePopupContext } from "../../context/context";
import { styled } from "styled-components";
import PopupCard from "./PopupCard";
import { workPageData } from "../../portfolio";
import ImageCard from "./ImageCard";
import { Icon } from "@iconify/react";

const Popup: React.FC = () => {
  const { isPopupOpen, closePopup, projectId } = usePopupContext();

  if (!isPopupOpen) {
    return null;
  }

  const pro = workPageData.find((i) => i.id === projectId);

  return (
    <Container>
      <Overlay onClick={closePopup} />
      <PopupContainer>
        <Header>
          <PopupHeading>{pro?.title}</PopupHeading>
          <Icon icon="basil:cross-outline" onClick={closePopup} />
        </Header>
        <Content>
          <ImageCard title={pro?.thumbnail.title} src={pro?.thumbnail.src} />

          {pro?.projectData.map((p, i) => (
            <PopupCard
              key={i.toString()}
              title={p?.title}
              description={p?.description}
              bullets={p?.bullets}
            />
          ))}
          {pro?.projectImages.map((i) => (
            <ImageCard title={i.title} src={i.src} />
          ))}
        </Content>
      </PopupContainer>
    </Container>
  );
};

export default Popup;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Overlay = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  top: 0;
`;

const PopupContainer = styled.div`
  background-color: white;
  max-width: 700px;
  width: 100%;
  height: 90vh;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const Content = styled.div`
  overflow-y: auto;
  height: 94%;
  width: 100%;
  position: relative;
  padding-right: 2px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Header = styled.div`
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  svg {
    cursor: pointer;
    color: rgb(6 9 18);
  }
`;

const PopupHeading = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: rgb(6 9 18);
`;
