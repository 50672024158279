import { FC } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Link } from "react-scroll";

interface MenuItemProps {
  route: { name: string; path: string };
  onClick: () => void;
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuItem: FC<MenuItemProps> = ({ route, onClick }) => {
  return (
    <MotionLi
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
    >
      <TextPlaceholder>
        <Link
          spy={true}
          smooth={true}
          duration={500}
          onClick={onClick}
          to={route.path}
        >
          {route.name}
        </Link>
      </TextPlaceholder>
    </MotionLi>
  );
};

export default MenuItem;

const MotionLi = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TextPlaceholder = styled.div`
  border-radius: 5px;
  flex: 1;
  font-size: 40px;
  font-weight: 600;
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #00b4d8;
    }
  }
`;
