import React from "react";
import styled from "styled-components";
const PopupCard = ({
  id,
  title,
  description,
  bullets,

}: {
  id?: string
  title?: string;
  description?: string;
  bullets?: string[];
}) => {
  return (
    <div id={id}>
      <Heading>{title}</Heading>
      <Description>{description}</Description>
      <BulitsContainer>
        {bullets && bullets?.map((i) => <Bulits>{i}</Bulits>)}
      </BulitsContainer>
    </div>
  );
};

export default PopupCard;

const Heading = styled.h2`
  font-size: 16px;
  margin: 15px 0px 8px 0px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 25px;
`;

const BulitsContainer = styled.div``;

const Bulits = styled.li`
  font-size: 16px;
  line-height: 25px;
`;
