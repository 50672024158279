import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { TypeAnimation } from "react-type-animation";
import Reveal from "../components/Reveal";
import SocialIcons from "../components/SocialIcons";
import { homePageData } from "../portfolio";
import { Element } from "react-scroll";
import { Tooltip } from "react-tooltip";

interface HomePageProps {}

const HomePage: FC<HomePageProps> = ({}) => {
  return (
    <Element name="home">
      <MainContainer>
        <div>
          <div>
            <HeroSection />
            <Container>
              <Content>
                <Reveal>
                  <ImageWarpper>
                    <AnimationBox />
                    <ImageBox>
                      <ImageBorder>
                        <span />
                        <Image
                          src={homePageData.image}
                          alt="Upwork Ahmad Raza"
                        />
                      </ImageBorder>
                    </ImageBox>
                  </ImageWarpper>
                </Reveal>

                <Reveal>
                  <HeadingAnimation
                    sequence={homePageData.headings}
                    wrapper="span"
                    speed={30}
                    repeat={Infinity}
                  />
                </Reveal>

                <Reveal>
                  <Description>{homePageData.description}</Description>
                </Reveal>

                <Reveal>
                  <IconContainer>
                    {homePageData.socialLinks.map((i) => (
                      <>
                        <SocialIcons key={i.name} icon={i} />
                        <Tooltip id={`social-icon-${i.id}`} />
                      </>
                    ))}
                  </IconContainer>
                </Reveal>
              </Content>
            </Container>
          </div>
        </div>
      </MainContainer>
    </Element>
  );
};

const MainContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  > div {
    width: 100%;
    > div {
      overflow: hidden;
      position: relative;
    }
  }
`;

const HeroSection = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(/herosection-bg.png);
  background-repeat: repeat;
  background-size: initial;
  background-position: 0 13px;
  position: absolute;
  left: 0;
  top: 0;
`;

const Container = styled.div`
  overflow: auto;
  height: 100vh;
  display: flex;
  width: 100%;
  background-color: rgb(11 18 35/0.9);
  position: relative;
  z-index: 0;
`;
const rotateAnimation = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  margin: auto;

  overflow: auto;
  padding: 1rem;
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 640px) {
    max-width: 640px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageWarpper = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 99999px;
  overflow: hidden;
  opacity: 1;
  transform: none;
  margin-bottom: 1.25rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

const AnimationBox = styled.span`
  background-image: linear-gradient(
    to top right,
    transparent,
    #00b4d8,
    rgba(114, 226, 174, 0)
  );
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${rotateAnimation} 2s linear infinite;
`;

const ImageBox = styled.div`
  height: 150px;
  width: 150px;
  vertical-align: middle;
  border: solid 6px rgb(114 226 174/0.1);
  border-radius: 9999px;
  overflow: hidden;
  display: inline-block;
  z-index: 20;
  position: relative;
`;

const ImageBorder = styled.span`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  vertical-align: middle;
  span {
    box-sizing: border-box;
    display: block;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 100% 0px 0px;
  }
`;

const Image = styled.img`
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  vertical-align: middle;
`;

const HeadingAnimation = styled(TypeAnimation)`
  margin-bottom: 1.25rem;
  font-size: 40px;
  font-weight: 700;
  display: inline-block;
  color: #00b4d8;
  text-align: center;
  &::before {
    content: "Hi, I am";
    color: #fff;
  }
`;

const Description = styled.p`
  color: rgb(191 190 203/1);
  font-size: 18px;
  line-height: 1.5;
  opacity: 1;
  transform: none;
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: center;
`;

export default HomePage;
