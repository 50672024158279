import React, { createContext, useContext, useState, ReactNode } from "react";

interface PopupContextType {
  isPopupOpen: boolean;
  openPopup: (id: string) => void;
  closePopup: () => void;
  projectId: string
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export function usePopupContext() {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopupContext must be used within a PopupProvider");
  }
  return context;
}

interface PopupProviderProps {
  children: ReactNode;
}

export function PopupProvider({ children }: PopupProviderProps) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [projectId , setProjectId] = useState('')

  const openPopup = (id: string) => {
    setPopupOpen(true);
    setProjectId(id)

  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const contextValue: PopupContextType = {
    isPopupOpen,
    openPopup,
    closePopup,
    projectId
  };

  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
}