import React, { FC } from "react";
import { keyframes, styled } from "styled-components";

interface PageHeadingProps {
  heading : string
  backgroundText: string
}



const PageHeading : FC<PageHeadingProps> = ({ heading, backgroundText }) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <Devider>
        <InnerDivider />
      </Devider>
      <SubHeading>{backgroundText}</SubHeading>
    </Container>
  );
};

export default PageHeading;

const Container = styled.div`
  text-align: center;
  height: 150px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 36px;
  color: #fff;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  line-height: 1.2;
`;

const Devider = styled.span`
  width: 8rem;
  height: 0.375rem;
  position: relative;
  z-index: 10;
  display: inline-block;
  border-radius: 50px;
  background: #233f45;
  opacity: 0.4;
  overflow: hidden;
`;

const titleDeviderAnimation = keyframes`
0%{
    transform: translateX(-6px);
}
100%{
    transform: translateX(128px);
}
`;

const InnerDivider = styled.span`
  width: 0.375rem;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: ${titleDeviderAnimation} 3s ease-in-out infinite;
  animation: ${titleDeviderAnimation} 3s ease-in-out infinite;
  background: #72e2ae;
  border-radius: 100%;
`;

const SubHeading = styled.span`
  font-size: 8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.05;
  position: absolute;
  top: -22px;
  right: 0;
  left: 0;
`;
