import { FC } from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import { resumePageData } from "../portfolio";
import Reveal from "../components/Reveal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PageHeading from "../components/PageHeading";
import { Element } from "react-scroll";
import Section from "../components/Section";

interface SkillsProps {}

const Skills: FC<SkillsProps> = ({}) => {
  return (
    <Section name="skills">
      <Container>
        <SkillContainer>
          {resumePageData.skills.map(
            (i: { title: string; icon: string; background?: string }, n) => (
              <>
                <Reveal>
                  <SkillIcon
                    data-tooltip-id={`tooltip-${n}`}
                    data-tooltip-content={i.title}
                    key={i.title}
                  >
                    <Icon icon={i.icon} />
                  </SkillIcon>
                </Reveal>

                <ReactTooltip id={`tooltip-${n}`} />
              </>
            )
          )}
        </SkillContainer>
      </Container>
    </Section>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SkillIcon = styled.a`
  height: 90px;
  width: 90px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  max-width: 100%;
  column-gap: 25px;
  row-gap: 50px;
  padding: 1.25rem;

  svg {
    height: 50px;
    width: 40px;
    border-radius: 8px;
  }
  @media (min-width: 550px) {
    column-gap: 30px;
    svg {
      height: 50px;
      width: 50px;
    }
  }
  @media (min-width: 768px) {
    column-gap: 45px;
    svg {
      height: 80px;
      width: 80px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 1000px;
    column-gap: 60px;
  }
`;

export default Skills;
