import { FC, useState } from "react";
import styled from "styled-components";
import { usePopupContext } from "../../context/context";
import { Icon } from "@iconify/react";

interface ProjectCardProps {
  project: {
    id: string;
    title: string;
    category: string[];
    link: string;
    thumbnail: {
      title: string;
      src: string;
    };
    projectData: (
      | {
          title: string;
          description: string;
          bullets?: undefined;
        }
      | {
          title: string;
          description: string;
          bullets: string[];
        }
      | undefined
    )[];
    projectImages: {}[];
  };
}

const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const [overly, setOverly] = useState(false);

  const { openPopup } = usePopupContext();

  return (
    <Container
      onMouseEnter={(e) => {
        setOverly(true);
      }}
      onMouseLeave={() => setOverly(false)}
    >
      <ImageBox>
        <Image src={project.thumbnail.src} alt={project.thumbnail.title} />
        <Overly style={{ width: overly ? "100%" : "0" }}>
          <a target="_blank" href={project.link}>
            <Button>
              <Icon icon="ph:link-bold" />
            </Button>
          </a>
          <Button onClick={() => openPopup(project.id)}>
            <Icon icon="ep:arrow-right" />
          </Button>
        </Overly>
      </ImageBox>
      <div>
        <Title>{project.title}</Title>
        <SubHeading>
          {project.category.map((c, i) => (
            <span key={i}>
              {c}
              {i !== project.category.length - 1 && "/"}
            </span>
          ))}
        </SubHeading>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(255 255 255/0.1);
  background-color: rgb(22 32 51/1);
  backdrop-filter: blur(12px);
`;

const ImageBox = styled.div`
  position: relative;
  overflow: hidden;
`;

const Overly = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #0000005e;
  overflow: hidden;
  transition: 0.4s;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  object-fit: cover;
  height: 217px;
  width: 312px;
`;

const Title = styled.h5`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  color: #ffffff;
`;

const SubHeading = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: rgb(191 190 203/1);
`;

const Button = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #00b4d8;
  border-radius: 340px;
  cursor: pointer;
  margin: 0px 10px;
  &:hover {
    background: #fff;
  }
  > svg {
    color: #0f172a;
  }
`;

export default ProjectCard;
