import { FC } from "react";
import styled from "styled-components";

interface ImageCardProps {
  title: string | undefined;
  src: string | undefined;
}

const ImageCard: FC<ImageCardProps> = ({ title, src }) => {
  return (
    <Container>
      <Image src={src} alt={title} />
      <Title>{title}</Title>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`;

const Image = styled.img`
  width: 100%;
`;

const Title = styled.p`
  font-style: italic;
`;

export default ImageCard;
