import { FC } from "react";
import styled from "styled-components";

interface ResumeCardProps {
  date: string;
  role: string;
  company: string;
  descriptions: string[];
}

const ResumeCard: FC<ResumeCardProps> = ({
  date,
  role,
  company,
  descriptions,
}) => {
  return (
    <MainContainer>
      <Container>
        <TimeLineYear>{date}</TimeLineYear>
        <TimeLineRight>
          <Warpper>
            <Circle />
            <MidLine />
          </Warpper>
          <Title>{role}</Title>
          <SubHeading>{company}</SubHeading>
          {descriptions.map((desc) => (
            <ul>
              <li>
                <Desc>{desc}</Desc>
              </li>
            </ul>
          ))}
        </TimeLineRight>
      </Container>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  opacity: 1;
  transform: none;
`;

const Container = styled.div`
  margin-top: 0;
  border-radius: 9999px;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
  }
`;

const TimeLineYear = styled.span`
  position: relative;
  line-height: 1;
  font-size: 14px;
  text-align: center;
  padding: 0.25rem;
  background-color: rgb(114 226 174/0.1);
  border-radius: 9999px;
  min-width: 140px;
  margin-right: 3rem;
  margin-top: 1.75rem;
  top: 0;
  left: 0;
  color: rgb(191 190 203/1);

  @media (max-width: 768px) {
    left: 3rem;
    position: absolute;
  }
`;

const TimeLineRight = styled.div`
  padding-top: 4rem;
  padding-left: 3rem;
  border-left: 4px solid rgb(255 255 255/0.2);
  position: relative;
  color: #fff;

  @media (min-width: 768px) {
    padding-top: 1.5rem;
  }
`;

const Warpper = styled.span`
  background-color: rgb(255 255 255/0.2);
  border-radius: 9999px;
  width: 0;
  height: 100%;
  margin-top: 0.25rem;
  left: -0.125rem;
  top: 0;
  position: absolute;
`;

const Circle = styled.span`
  background-color: rgb(15 23 42/1);
  border: 4px solid rgb(255 255 255/0.2);
  border-radius: 9999px;
  transform: translate(-50%, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  z-index: 10;
  top: 1.5rem;
  left: 0;
  position: absolute;
`;

const MidLine = styled.span`
  transform: translate(0px, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
  width: 2rem;
  left: 0;
  background-color: rgb(255 255 255/0.2);
  border-radius: 9999px;
  height: 0.25rem;
  margin-top: 0.625rem;
  z-index: 1;
  right: 100%;
  top: 1.5rem;
  position: absolute;

  @media (min-width: 768px) {
    transform: translate(-50%, 0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
    width: 5rem;
    left: 50%;
  }
`;

const Title = styled.h5`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  color: #00b4d8;
`;

const SubHeading = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: rgb(191 190 203/1);
`;

export default ResumeCard;
