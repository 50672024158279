import React from "react";
import ProjectCard from "./ProjectCard";
import styled from "styled-components";
import Reveal from "../Reveal";
import { workPageData } from "../../portfolio";

interface Project {
  id: string;
  title: string;
  category: string[];
  link: string;

  thumbnail: {
    title: string;
    src: string;
  };
  projectData: (
    | {
        title: string;
        description: string;
        bullets?: undefined;
      }
    | {
        title: string;
        description: string;
        bullets: string[];
      }
    | undefined
  )[];
  projectImages: {}[];
}

interface ProjectListProps {}

const ProjectList: React.FC<ProjectListProps> = () => {
  return (
    <Container>
      {workPageData.map((project) => (
        <Reveal key={project.title}>
          <ProjectCard project={project} />
        </Reveal>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  gap: 1.75rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 772px) {
    max-width: 1118px;
    justify-content: start;
  }
`;

export default ProjectList;
