import { styled, keyframes } from "styled-components";
import { aboutPageData } from "../portfolio";
import PageHeading from "../components/PageHeading";
import { Element } from "react-scroll";
import Reveal from "../components/Reveal";
import Section from "../components/Section";

const About = () => {
  const handleDownload = () => {
    // Construct the URL for the PDF file
    
    const pdfUrl = `https://drive.google.com/file/d/1P4COLYsMD54xNINzj4H1r-o430TLOMpQ/view?usp=sharing`;

    // Create an anchor element and trigger the download
    const anchor = document.createElement("a");
    anchor.target = "__blank";
    anchor.href = pdfUrl;
    anchor.download = "Ahmad.pdf";
    anchor.click();
  };
  return (
    <Section name="about">
      <AboutImageDiv>
        <Reveal>
          <AboutInnerDiv>
            <TopBorderAnimate />
            <BottomBorderAnimate />
            <LeftBorderAnimate />
            <RightBorderAnimate />
            <ImageDiv>
              <img width="100%" alt="" src={aboutPageData.image} />
            </ImageDiv>
          </AboutInnerDiv>
        </Reveal>
      </AboutImageDiv>

      <AboutContent>
        <Reveal>
          <>
            <AboutHeading>{aboutPageData.heading}</AboutHeading>
            <AboutExperienceList>
              {aboutPageData.experienceList.map((i, n) => (
                <li key={n.toString()}>{i}</li>
              ))}
            </AboutExperienceList>
            {aboutPageData.description.map((desc, i) => (
              <AboutDescription key={i.toString()}>{desc}</AboutDescription>
            ))}

            <ResumeButton onClick={handleDownload}>
              Download Resume
            </ResumeButton>
          </>
        </Reveal>
      </AboutContent>
    </Section>
  );
};

export default About;

const Container = styled.div`
  width: 100%;
  background-color: rgb(11 18 35/0.9);
  z-index: 20;
  padding-top: 6rem;
  padding-bottom: 6rem;
`;
const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const AboutImageDiv = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  opacity: 1;
  transform: none;
  margin-top: 20px;
  padding: 0px 20px;
  @media screen and (min-width: 1024px) {
    width: 50%;
    margin-top: 80px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 0px;
  }
`;

const AboutInnerDiv = styled.div`
  border: 10px solid #081012;
  position: relative;
  --tw-border-opacity: 0.2;
  box-sizing: border-box;
`;

const ledgerLeftRight = keyframes`
0%{
    left: -40px
}
100%{
    left: 100%
}
`;
const ledgerRightLeft = keyframes`
0%{
    right: -40px
}
100%{
    right: 100%
}
`;

const ledgerBottomTop = keyframes`
0%{
    bottom: -40px
}
100%{
    bottom: 100%
}
`;

const ledgerTopBottom = keyframes`
0%{
    top: -40px
}
100%{
    top: 100%
}
`;

const TopBorderAnimate = styled.span`
  verticle-align: middle;
  --tw-gradient-to: #00b4d8;
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  border-radius: 9999px;
  width: 2.5rem;
  height: 0.625rem;
  z-index: 10;
  left: 0;
  top: -0.625rem;
  position: absolute;
  -webkit-animation: ${ledgerLeftRight} 3s ease-in-out infinite;
  animation: ${ledgerLeftRight} 3s ease-in-out infinite;
  --tw-border-opacity: 0.2;
  --tw-bg-opacity: 0.95;
`;

const BottomBorderAnimate = styled.span`
  verticle-align: middle;
  --tw-gradient-to: #00b4d8;
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  border-radius: 9999px;
  width: 2.5rem;
  height: 0.625rem;
  z-index: 10;
  left: auto;
  bottom: -0.625rem;
  top: auto;
  position: absolute;
  -webkit-animation: ${ledgerRightLeft} 3s ease-in-out infinite;
  animation: ${ledgerRightLeft} 3s ease-in-out infinite;
  --tw-border-opacity: 0.2;
  --tw-bg-opacity: 0.95;
`;

const LeftBorderAnimate = styled.span`
  verticle-align: middle;
  --tw-gradient-to: #00b4d8;
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  border-radius: 9999px;
  width: 0.625rem;
  height: 2.5rem;
  z-index: 10;
  top: auto;
  left: -0.625rem;
  position: absolute;
  -webkit-animation: ${ledgerBottomTop} 3s ease-in-out infinite;
  animation: ${ledgerBottomTop} 3s ease-in-out infinite;
  --tw-border-opacity: 0.2;
  --tw-bg-opacity: 0.95;
`;

const RightBorderAnimate = styled.span`
  verticle-align: middle;
  --tw-gradient-to: #00b4d8;
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, transparent);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  border-radius: 9999px;
  width: 0.625rem;
  height: 2.5rem;
  z-index: 10;
  left: auto;
  right: -0.625rem;
  position: absolute;
  -webkit-animation: ${ledgerTopBottom} 3s ease-in-out infinite;
  animation: ${ledgerTopBottom} 3s ease-in-out infinite;
  --tw-border-opacity: 0.2;
  --tw-bg-opacity: 0.95;
`;

const ImageDiv = styled.span`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
`;

const AboutContent = styled.div`
  width: 100%;
  opacity: 1;
  padding: 10px 20px;
  transform: none;
  @media screen and (min-width: 1024px) {
    width: 50%;
    padding-left: 30px;
  }
`;
const AboutHeading = styled.h3`
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  --tw-text-opacity: 1;
  color: #00b4d8;
  &::before {
    content: "Hi, I am ";
    color: #fff;
  }
`;

const AboutExperienceList = styled.ul`
  list-style: none;
  margin-top: 20px;
  color: #fff;
  line-height: 1.4;
  padding: 0px;
  font-size: 15px;
`;

const AboutDescription = styled.p`
  margin-top: 20px;
  color: #fff;
  line-height: 1.4;
  font-size: 15px;
`;

const ResumeButton = styled.button`
  display: inline-block;
  margin-top: 30px;
  height: auto;
  min-height: 0;
  width: auto;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.25rem;
  border-width: 0;
  --tw-bg-opacity: 1;
  background-color: #00b4d8;
  padding: 0.625rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
  &:hover {
    transition-duration: 0.5s;
    background: #fff;
    transform-origin: top;
    --tw-scale-y: 1;
  }
`;
