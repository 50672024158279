import { FC } from "react";
import styled from "styled-components";
import PageHeading from "../components/PageHeading";
import ProjectList from "../components/ProjectCatalog/ProjectList";
import { Element } from "react-scroll";
import Section from "../components/Section";

interface WorkProps {}

const Work: FC<WorkProps> = ({}) => {
  return (
    <Section name="work">
      <Content>
        <ProjectList />
      </Content>
    </Section>
  );
};

const MainContainer = styled.div`
  width: 100%;
  background-color: rgb(11 18 35/0.9);
  z-index: 20;
  padding-top: 6rem;
  padding-bottom: 3rem;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default Work;
