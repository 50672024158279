import * as React from "react";
import { motion } from "framer-motion";
import MenuItem from "./MenuItem";
import styled from "styled-components";
const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const routes = [
  { name: "Home", path: "home" },
  { name: "About", path: "about" },
  { name: "Services", path: "services" },
  { name: "Skills", path: "skills" },

  { name: "Resume", path: "resume" },
  { name: "Reviews", path: "feedback" },
  { name: "Contact", path: "contact" },
];

export const Navigation = ({
  style,
  onClick,
}: {
  style?: object;
  onClick: () => void;
}) => (
  <MotionUl style={style} variants={variants} onClick={() => null}>
    {routes.map((route) => (
      <MenuItem key={route.name} onClick={onClick} route={route} />
    ))}
  </MotionUl>
);

const MotionUl = styled(motion.ul)`
  margin: 0;
  padding: 0;
  padding: 25px;
  position: absolute;
  top: 100px;
  max-width: 230px;
  width: 100%;
  z-index: 9999;
`;
